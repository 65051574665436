/** @format */

.customTable {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-collapse: collapse;
  border-radius: 10px;
  margin: 8px 0 0 0;

  thead {
    font-size: calc(var(--pc-font-small) + 2px);
    height: 35px;

    tr {
      th {
        padding: 5px 10px 5px 10px !important;
        border-right: 1px solid var(--tertiary-text-color);
        text-align: left;

        &:nth-last-child(1) {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
        td{
            padding: 5px 10px 5px 10px !important;
            font-weight: 400 ;
            font-size: calc(var(--pc-font-small));
        }
    }
  }
}
