/** @format */

.Timeline {
  .date,
  .day {
    color: var(--primary-text-color);
    font-size: var(--pc-font-medium);
  }
  .day {
    color: var(--secondary-text-color);
  }

  .eventMark {
    position: absolute;
    right: -6.5px;
    top: 0;
    height: 10px;
    width: 10px;
    background-color: var(--tertiary-text-color);
    border-radius: 50%;
    z-index: 998;
  }

  .timeline_EventsCtn {
    // height: 100px;
    border-left: 2px dashed var(--quaternary-text-color);
    box-sizing: border-box;
    padding: 0 15px 0 15px;

    .timeline_event {
      width: 100%;
      min-height: 200px;
      background-color: var(--secondary-bg-color);
      border: 1px solid var(--quaternary-bg-color);
      border-radius: 15px;
      transition: all 0.1s ease-in-out;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px 10px 20px;
      box-sizing: border-box;
      margin: 0 0 20px 0;
      cursor: pointer;

      &:hover {
        background-color: var(--tertiary-bg-color);
      }

      .eventInfo {
        list-style: none;
        padding: 0;
        margin: 0;
        max-width: calc(100% - 170px);

        li {
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            font-size: var(--pc-font-small);
            color: var(--secondary-text-color);
            margin: 0 0 10px 0;
          }

          &:nth-child(1) {
            margin-top: 5px;
            font-size: var(--pc-font-medium);
          }

          &:nth-child(2) {
            font-size: calc(var(--pc-font-large) - 2px);
            color: var(--primary-text-color);
            margin: 0 0 10px 0;
          }

          &:nth-child(5) {
            font-size: var(--pc-font-small);
            background: var(--success-btn-bg-color);
            color: var(--primary-text-color);
            width: 140px;
            padding: 4px 7px 4px 7px;
            border-radius: 4px;
            text-align: center;
            margin: 15px 0 0 0;
          }

          .icon,
          .avatar {
            transform: translateY(3px);
            margin: 0 5px 0 0;
            font-size: var(--pc-font-medium);
          }

          .avatar {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0 3px 0 0;
          }
        }
      }

      .imgCtn {
        height: 100%;
        position: relative;
        width: 150px;

        .eventImg {
          width: 150px;
          height: 150px;
          border-radius: 15px;
          position: absolute;
          top: 50%;
          transform: translateY(20%);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .date,
  .day {
    font-size: 12px !important;
  }

  .timeline_event {
    position: relative;

    .eventInfo {
      width: 100%;
      max-width: 100% !important;

      li {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          font-size: 11px !important;
          color: var(--secondary-text-color);
          margin: 0 0 10px 0;
        }
      }
    }

    .imgCtn {
      position: absolute !important;
      top: -20% !important;
      right: 15px !important;
      height: 70px !important;
      width: 70px !important;

      .eventImg {
        height: 70px !important;
        width: 70px !important;
      }
    }
  }
}
