/** @format */

.Quiz {
  .quizHeader {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: var(--pc-font-medium);
    }

    .subtitle {
      font-size: var(--pc-font-small);
      color: var(--secondary-text-color);
      font-weight: 400;
      margin: 0 0 7px 0;
    }

    .createQuizBtn {
      border-radius: 8px;
    }
  }

  .quizList {
    margin: 20px 0 0 0;
    display: flex;
    flex-wrap: wrap;

    .quizView {
      border-radius: 12px;
      padding: 15px;
      box-sizing: border-box;
      margin: 0 10px 15px 10px;
      border: 1px solid var(--event-theme-color);
      min-width: calc(100% / 2 - 20px);
      max-width: calc(100% / 2 - 20px);
      position: relative;

      .name {
        font-size: var(--pc-font-medium);
        display: flex;
        align-items: center;

        label {
          margin-left: 7px;
          padding: 2px 5px 2px 5px;
          border-radius: 4px;
          font-size: calc(var(--pc-font-small));
          font-weight: 400;
        }
      }

      .description {
        margin: 2px 0 0 0;
        font-size: calc(var(--pc-font-medium) - 2px);
        font-weight: 400;
      }

      .additionalInfo {
        list-style: none;
        margin: 15px 0 0 0;
        padding: 0;

        li {
          font-size: calc(var(--pc-font-medium) - 2px);
          font-weight: 400;
        }
      }

      .btnCtn {
        position: absolute;
        right: 10px;
        top: 11px;

        .editBtn,
        .deleteBtn {
          font-size: var(--pc-font-small);
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }

        .deleteBtn {
          color: red;
          margin-left: 8px;
        }
      }
    }
  }
}
.createQuizModal {
  .inputField {
    margin: 8px 0 20px 0;

    .label {
      font-size: calc(var(--pc-font-medium));
      margin: 0 0 3px 0;
    }

    .input {
      background-color: var(--tertiary-bg-color);
      border-radius: 5px;
      padding: 5px;
      box-sizing: border-box;
      font-size: calc(var(--pc-font-medium) - 1px);
    }

    .addDeadline {
      font-weight: 400;
      color: var(--secondary-text-color);
      font-size: calc(var(--pc-font-medium) - 1px);
      cursor: pointer;
      transition: all 0.12s linear;

      &:hover {
        opacity: 0.7;
      }

      .icon {
        font-size: calc(var(--pc-font-medium) - 5px);
        margin: 0 3px 0 0;
        transform: translateY(1px);
      }
    }
  }

  .createBtn {
    margin: 10px 0 0 0;
    width: 100%;
    border-radius: 8px;
    background: var(--brand-color-light);
  }

  .manageQuestionBtn {
    margin: 0 0 5px 0;
    width: 100%;
    border-radius: 8px;
  }

  .lastChangeNoti {
    margin: 8px 0 0 0;
    font-size: var(--pc-font-small);
    text-align: center;
    font-weight: 400;
  }
}

.questionManaged {
  position: relative;
  .quizName {
    margin: 10px 0 0 0;
    font-size: calc(var(--pc-font-large));
  }

  .numOfQues {
    font-weight: 400;
    font-size: calc(var(--pc-font-medium));
  }

  .addQuestionBtn {
    position: absolute;
    right: 0;
    top: 50px;
    border-radius: 8px;
  }

  .question {
    margin: 20px 0 0 0;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid var(--tertiary-text-color);
    border-radius: 10px;

    .nameInput {
      width: 100%;
      background: none;
      border: none;
      outline: none;
      color: var(--primary-text-color);
      resize: none;
      padding: 0;
      // border-bottom: 1px solid var(--tertiary-text-color);
      font-size: calc(var(--pc-font-medium) + 1px);

      &::placeholder {
        color: var(--tertiary-text-color);
      }
    }

    .answersCtn {
      margin: 0 0 10px 0;

      .answer {
        display: flex;
        align-items: center;

        .checkIcon {
          margin: 0 10px 0 0;
          font-size: 11px;
          cursor: pointer;
          color: var(--tertiary-text-color);
          transition: all 0.12s linear;

          &:hover {
            color: var(--secondary-text-color);
          }
        }

        .isCorrect {
          font-weight: 900;
          color: var(--success-btn-bg-color);

          &:hover {
            color: var(--success-link-hover-color);
          }
        }

        label {
          margin: 0 8px 0 0;
        }

        .deleteAns {
          color: red;
          font-size: var(--pc-font-small);
          cursor: pointer;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;

      .optionsBtn {
        display: flex;
        align-items: center;
        .delete {
          color: red;
          font-size: var(--pc-font-small);
          cursor: pointer;
        }

        select {
          cursor: pointer;
          border: none;
          outline: none;
          font-weight: 400;
          background: none;
          border-radius: 3px;
          margin: 0 5px 0 0;
          font-size: var(--pc-font-small);
          color: var(--primary-text-color);
        }
      }
    }
  }

  .mainBtnCtn {
    margin: 20px 0 0 0;
    text-align: center;

    button {
      width: calc(50% - 8px);
      margin: 0 4px;
      border-radius: 8px;
    }

    .notChangeText {
      font-weight: 400;
      font-size: var(--pc-font-medium);
      color: var(--secondary-text-color);
    }
  }
}
