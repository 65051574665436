/** @format */

.waitingAnim {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 0 2px;

  &.bigAnim {
    transform: translateY(15px);

    li {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin: 0 5px 0 0;
      background-color: var(--event-theme-color);
    }
  }

  li {
    animation: pendingAnim 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes pendingAnim {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-3.5px);
  }
  100% {
    transform: translateY(0);
  }
}
