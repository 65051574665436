$paddingLeftIcon: 2.3rem;
$paddingRightIcon: 2rem;
$borderRadius: 1.3rem;
$weak-color: var(--cranberry-60);
$medium-color: var(--yellow-50);
$strong-color: var(--green-40);

.login-box {

  .icon {
    width: 1.5rem;
    color: var(--primary-text-color);
  }

  .user-box {
    position: relative;
    border-radius: $borderRadius;

    input {
      width: calc(100% - $paddingLeftIcon - $paddingRightIcon);
      padding: 12px $paddingRightIcon 12px $paddingLeftIcon;
      font-size: 16px;
      color: var(--primary-text-color);
      border: none;
      outline: none;
      background: transparent;
      border-radius: $borderRadius;
      padding-right: 2rem !important;

      &:focus~label,
      &:valid~label {
        top: -20px;
        left: 0;
        color: var(--tertiary-text-color);
        font-size: var(--pc-font-small);
      }

      &:focus {
        border: 1px solid var(--quaternary-text-color);
      }
    }

    .icon {
      position: absolute;
      font-size: var(--pc-font-medium);
      bottom: 0;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }

    label {
      position: absolute;
      top: 1.5px;
      left: 0;
      padding: 10px 0rem 10px $paddingLeftIcon;
      font-size: 16px;
      color: #fff;
      pointer-events: none;
      transition: .3s;
    }
  }

}


.user-box {
  .indicator {
    width: calc(100% - $paddingRightIcon - $paddingLeftIcon);
    height: var(--height);
    position: absolute;
    left: $paddingLeftIcon;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      border-radius: 5px;
      background: transparent;
      z-index: 3;
      transition: background .6s ease-out, width .6s ease-out;
    }

    &.progress1 {
      &::before {
        width: 20%;
        background: $weak-color;
      }
    }

    &.progress2 {
      &::before {
        width: 40%;
        background: $weak-color;
      }
    }

    &.progress3 {
      &::before {
        width: 60%;
        background: $medium-color;
      }
    }

    &.progress4 {
      &::before {
        width: 80%;
        background: $medium-color;
      }
    }

    &.progress5 {
      &::before {
        width: 100%;
        background: $strong-color;
      }
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.visible-password {
  position: absolute;
  bottom: 5px;
  right: calc($paddingRightIcon - 1.5rem);
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-menu {

  .dropdown {
    cursor: pointer;
  }

  .dropdown:empty~label {
    top: -20px;
    left: 0;
    color: var(--tertiary-text-color);
    font-size: var(--pc-font-small);
  }

  .menu-content {
    opacity: 0;
    position: absolute;
    top: 3.5rem;
    left: calc($paddingLeftIcon - $borderRadius + 10px);
    background: linear-gradient(to bottom, var(--tertiary-bg-color), var(--secondary-bg-color));
    border-radius: $borderRadius;
    width: calc(100% - $paddingLeftIcon - $paddingRightIcon + $borderRadius);
    z-index: 1;
    transition: all .3s ease-in-out;

    ul {
      padding: 1rem 1rem 1rem 1rem;
      margin: 0;

      li {
        list-style: none;
        cursor: pointer;
        width: calc(100% - 5px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all .1s ease-in-out;
        padding: 1px 5px;
        border-radius: 5px;

        &:hover {
          background-color: var(--quaternary-text-color);
        }

      }

    }

    &.active-menu {
      opacity: 1;
    }

  }
}




/** @format */

.textInput {
  width: 100%;
  height: auto;
  background-color: transparent;
  color: var(--primary-text-color);
  outline: none;
  border: none;
  resize: none;
  box-sizing: border-box;
  overflow: hidden;

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: var(--tertiary-text-color);
    opacity: 0.7;
  }

  &.large {
    font-size: var(--pc-font-huge);
  }

  &.medium {}

  &.small {}
}

.timePicker {
  display: flex;

  .ant-picker {
    background-color: var(--secondary-opacity-btn-bg-color) !important;
    color: var(--secondary-opacity-btn-bg-color) !important;
    border: none;
    height: 100%;
    max-width: 200px;
    border-radius: 7px 0 0 7px;

    &:nth-child(2) {
      margin: 0 0 0 4px;
      max-width: 100px;
      border-radius: 0 7px 7px 0;
    }

    &:hover {
      background-color: var(--secondary-opacity-btn-bg-hover-color) !important;
    }

    .ant-picker-input {
      svg {
        display: none;
      }

      input {
        color: var(--primary-text-color) !important;
        font-size: var(--pc-font-medium) !important;
        font-weight: 450;

        &::placeholder {
          color: var(--primary-text-color) !important;
        }
      }
    }
  }
}

// .ant-picker-date-panel-container {
//   background-color: var(--secondary-bg-color) !important;

//   .ant-picker-header {
//     color: var(--primary-text-color) !important;

//     .ant-picker-month-btn,
//     .ant-picker-year-btn {
//       &:hover {
//         color: var(--event-theme-color) !important;
//       }
//     }

//     .ant-picker-header-super-prev-btn,
//     .ant-picker-header-prev-btn,
//     .ant-picker-header-next-btn,
//     .ant-picker-header-super-next-btn {
//       color: var(--primary-text-color) !important;
//     }
//   }
//   .ant-picker-body {
//     .ant-picker-content {
//       th,
//       td {
//         color: var(--primary-text-color) !important;

//         .ant-picker-cell-range-end:not(.ant-picker-cell-disabled)
//           .ant-picker-cell-inner {
//         }
//       }
//       .ant-picker-cell-inner {
//         &::before {
//           border: 1px solid var(--event-theme-color) !important;
//         }
//       }
//     }
//   }
// }
// .ant-picker-footer .ant-picker-ranges {
//   .ant-picker-now a {
//     color: var(--event-theme-color) !important;

//     &:hover {
//       opacity: 0.7;
//     }
//   }

//   .ant-picker-ok {
//     button {
//       background: var(--event-theme-color) !important;

//       &:hover {
//         opacity: 0.7;
//       }
//     }
//   }
// }
// .ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-selected:not(
//     .ant-picker-cell-disabled
//   )
//   .ant-picker-cell-inner {
//   background: var(--event-theme-color) !important;
// }

// .ant-picker-dropdown
//   .ant-picker-time-panel-column
//   > li.ant-picker-time-panel-cell-selected
//   .ant-picker-time-panel-cell-inner {
//   background: var(--event-theme-color);

//   &:hover {
//     background: var(--event-theme-color-light);
//   }
// }

// .ant-picker-panel-container {
//   background: var(--secondary-bg-color) !important;

//   .ant-picker-time-panel-cell-inner {
//     color: var(--primary-text-color) !important;

//     &:hover {
//       background-color: var(--event-theme-color-light) !important;
//     }
//   }
// }

// .ant-switch.ant-switch-checked {
//   background: var(--event-theme-color) !important;
// }

.numberInput {
  width: 100%;
  height: calc(var(--pc-font-huge));
  font-size: var(--pc-font-medium);
  padding: 6px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: var(--quaternary-bg-color);
  color: var(--primary-text-color);
  outline: none;
  border: none;
}

@media screen and (max-width: 768px) { 
  .textInput {
    &.large {
      font-size: 20px !important;
    }
  }

  .timePicker {

  }
}