/** @format */

.searchEventCtn {
  min-height: 92vh;
  padding: 80px 0 0 0;
  box-sizing: border-box;

  .title {
    font-size: var(--pc-font-large);
  }

  .subtitle {
    font-size: calc(var(--pc-font-medium) - 2px);
    color: var(--secondary-text-color);
    font-weight: 400;
    margin: 0 0 15px 0;
  }

  .searchCtn {
    display: flex;
    margin: 0 0 25px 0;
    align-items: end;
    justify-content: start;
    // justify-content: center;

    .inputPart {
      input,
      select {
        width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
        background-color: var(--quaternary-bg-color);
        padding: 5px 12px 5px 12px;
        box-sizing: border-box;
        height: 30px;
        border-radius: 7px;
        color: var(--primary-text-color);
        font-size: calc(var(--pc-font-medium) - 3px);
      }

      .label {
        font-size: calc(var(--pc-font-medium) - 2px);
        margin: 0 0 2px 0;
      }

      &.Keyword {
        width: 300px;
        margin: 0 5px 0 0;
      }

      &.location {
        width: 200px;
        margin: 0 5px 0 0;
      }

      &.tag {
        width: 160px;
      }
    }
  }

  .searchBtn {
    border-radius: 10px;
    padding: 0;
    height: 30px;
    width: 30px;
    margin: 0 0 0 5px;
    background-color: var(--brand-color);

    &:hover {
      background-color: var(--brand-color-light);
    }

    .icon {
      font-size: calc(var(--pc-font-small) + 1px) !important;
      margin: 0;
      padding: 0;
    }
  }

  .subSearchContent {
    font-size: calc(var(--pc-font-medium));
    font-weight: 600;
    margin: 0 0 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .searchEventCtn {
    .title {
      font-size: 16px;
    }

    .subtitle {
      font-size: 11px;
    }

    .searchCtn {
      .inputPart {
        .label {
          font-size: 10px !important;
        }

        input,
        select {
          font-size: 9px !important;
        }

        &.Keyword {
          width: 250px;
          margin: 0 5px 0 0;
        }
  
        &.location {
          width: 200px;
          margin: 0 5px 0 0;
        }
  
        &.tag {
          width: 160px;
        }
      }

      .searchBtn {
        min-width: 30px !important;
      }
    }
  }
}
