/** @format */

.eventSlider {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 280px;
  overflow: hidden;

  .actionBtn {
    background: none;
    margin: 0;
    padding: 0;
    width: 18px;
    font-size: var(--pc-font-large);
    position: absolute;
    top: 40%;
    cursor: pointer;

    &:hover {
      background: none;
      color: var(--brand-color-light);
    }
  }

  .EventSliderCtn {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    margin-top: 7px;
    max-width: 100%;
    justify-content: center;
    animation-duration: 0.4s;
    animation-timing-function: ease-in;
    animation-direction: alternate;
    overflow: hidden;

    .eventCard {
      width: calc(100% / 3 - 40px);
      cursor: pointer;
      margin: 0 15px;
      //   transition: all 0.1s ease-in;

      .imgCard {
        width: 100%;
        border-radius: 12px;
      }

      .advertise {
        position: absolute;
        top: 3px;
        font-size: var(--pc-font-small);
        background: var(--error-btn-bg-hover-color);
        padding: 3px 8px;
        border-radius: 8px 5px 5px 0;
      }

      .eventName {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        font-size: var(--pc-font-medium);
      }

    }
  }

  .viewFullEvent {
    display: flex;

    img {
      height: 300px;
    }
  }
}

@keyframes scrollInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes scrollInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes scrollOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes scrollOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 768px) {
  .eventSlider {
    min-height: 80px !important;

    .eventCard {
      .advertise {
        font-size: 8px !important;
        padding: 2px 4px !important;
      }

      .eventName {
        font-size: 10px !important;
      }
    }
  }
}

