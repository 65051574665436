/** @format */

.eventsPreviewList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .eventRepresent {
    width: calc(50% - 15px);
    max-width: calc(50% - 15px);
    display: flex;
    margin: 0 0 20px 0;
    cursor: pointer;
    opacity: 0;
    animation: showUp 0.35s ease-out forwards;

    &:nth-child(odd) {
      margin-right: 22px;
    }

    .imgWrapper {
      img {
        // width: 100%;
        height: 100%;
        width: 160px;
        height: 160px;
        border-radius: 20px;
        margin: 0 10px 0 0;
        background-color: rgba(69, 69, 69, 0.386);
      }
    }

    .eventInfo {
      width: 100%;
      position: relative;
      // overflow: hidden;
    }

    .name {
      font-size: calc(var(--pc-font-medium));
      margin: 8px 0 0 8px;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .createdBy {
      display: flex;
      align-items: center;
      margin: 5px 0 0 0;
      position: absolute;
      bottom: 60px;
      width: 100%;

      img {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        margin: 8px;
      }

      label {
        font-size: calc(var(--pc-font-small) + 1px);
        color: var(--secondary-text-color);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .time {
      margin: 12px 0 0 8px;
      font-size: calc(var(--pc-font-small));
      color: var(--secondary-text-color);
      font-weight: 400;
      position: absolute;
      bottom: 32px;
    }

    .location {
      margin: 0 0 0 8px;
      font-size: calc(var(--pc-font-small));
      color: var(--secondary-text-color);
      font-weight: 400;
      width: 100%;
      text-overflow: ellipsis;
      text-wrap: wrap;
      position: absolute;
      bottom: 15px;
    }
  }
}

@keyframes showUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .eventsPreviewList {
    display: block !important;

    .eventRepresent {
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 0 10px 0 !important;

      .imgWrapper {
        img {
          width: 110px;
          height: 110px;
          border-radius: 12px;
        }
      }

      .name {
        font-size: 12px !important;
        margin: 0 0 0 8px !important;
      }

      .createdBy {
        img {
          width: 22px;
          height: 22px;
        }

        label {
          font-size: 10px;
        }
      }

      .time, .location {
        font-size: 10px;
      }
    }
  }
}
