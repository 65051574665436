.certificateCtn {
    min-height: 100vh;
    margin: 100px 0 20px 0;

    .certiImage {
        width: 100%;
    }

    .description {
        font-size: var(--pc-font-medium);
        font-weight: 400;
        text-align: center;
        margin: 12px 0 0 0;

        b {
            color: var(--brand-color-light);
            text-transform: uppercase;
        }
    }
}