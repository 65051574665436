/** @format */

.followBtn {
  transition: all 0.1s linear;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
    font-weight: 400;
  }
}

.flyingHeart {
  top: -10px;
  // right: 0;
  position: absolute;
  animation: flyAway 5s ease-out forwards;
}

@keyframes flyAway {
  0% {
    top: -10px;
    opacity: 1;
  }
  100% {
    top: -50vh;
    // right: -5px;
    opacity: 0;
  }
}
