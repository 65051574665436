/** @format */

.pricing {
  min-height: 100vh;
  padding-top: 100px;
  box-sizing: border-box;

  .title {
    font-size: var(--pc-font-huge);
  }

  .title_desc {
    font-size: var(--pc-font-medium);
    color: var(--secondary-text-color);
  }

  .subTitle {
    margin: 20px 0 0 0;
  }

  .subTitle_desc {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  .allPkgs {
    margin: 15px 0 40px 0;
    height: auto;

    .PackageCtn {
      width: 95%;
      height: 100%;
      border: 1px solid var(--quaternary-text-color);
      border-radius: 15px;
      background-color: var(--tertiary-bg-color);
      margin: 0 5%;
      box-sizing: border-box;
      padding: 20px;

      .packageTitle {
        font-size: calc(var(--pc-font-medium));
        margin: 0 0 12px 0;
      }

      .packagePrice {
        font-size: var(--pc-font-huge);
        line-height: 1;
      }

      .packageDuration {
        margin: 2px 0 0 0;
        font-size: calc(var(--pc-font-medium) - 1px);
        font-weight: 400;
      }

      .buyBtn {
        width: 100%;
        margin: 15px 0;
        font-size: calc(var(--pc-font-medium) + 2px);
        border-radius: 8px;
      }

      .buyPremiumBtn {
        background: var(--brand-color-light);
      }

      .packageDescription {
        font-size: calc(var(--pc-font-small) + 2px);

        .line {
          display: flex;
          align-items: start;
          margin: 7px 0;
          font-weight: 400 !important;

          .star {
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }

  .advPricePre {
    margin: 10px 0 30px 0;
    .money {
      font-weight: 400;
      text-align: center;

      b {
        color: var(--brand-color-light);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 18px !important;
  }

  .title_desc {
    font-size: 13px !important;
  }

  .subTitle {
    font-size: 15px !important;
  }

  .subTitle_desc {
    font-size: 10px !important;
  }

  .packageTitle {
    font-size: 11px !important;
  }

  .packagePrice {
    font-size: 22px !important;
  }

  .packageDuration {
    font-size: 12px !important;
  }

  .buyBtn {
    font-size: 12px !important;
  }

  .packageDescription {
    font-size: 10px !important;
  }

  .line {
    margin: 5px 0 !important;
  }
}
