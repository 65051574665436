/** @format */

.mainDashboardCtn {
  height: calc(100vh - 70px);
  width: 100vw;
  box-sizing: border-box;
  padding: 65px 0 15px 0;
  background-color: var(--primary-bg-color);

  .leftNav,
  .rightContent {
    width: 100%;
    min-height: 100%;
  }

  .leftNav {
    box-sizing: border-box;
    padding: 15px 25px 15px 25px;

    .NavBtn {
      margin: 0 0 3px 0;
      padding: 8px 10px 8px 10px;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.1s linear;

      &.active {
        background-color: var(--secondary-bg-color);
        &:hover {
          background-color: var(--secondary-bg-color);
        }
      }

      &:hover {
        background-color: var(--tertiary-bg-color);
      }
    }
  }

  .rightContent {
    background-color: white;
    background-color: var(--tertiary-bg-color);
    margin: 0 0 0 0;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 30px;
    max-height: calc(100vh - 80px - 70px);
    overflow-y: scroll;

    .MainText {
      font-size: var(--pc-font-large);
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0 0 0;

      .icon {
        margin: 0;
        padding: 0;
        font-size: calc(var(--pc-font-small) - 3px);
        margin: 0 0 0 4px;
      }
    }
    .title {
      font-size: var(--pc-font-medium);
    }

    .subtitle {
      font-size: calc(var(--pc-font-medium) - 2px);
      color: var(--secondary-text-color);
      margin: 0 0 0 0;
      font-weight: 400;
      line-height: 1;
    }

    .statCtn {
      display: flex;
      margin: 15px 0 0 0;
      height: 400px;

      .graphCtn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 400px;

        .totalUsers {
          background-color: var(--secondary-bg-color);
          border: 1px solid var(--tertiary-text-color);
          border-radius: 8px;
          width: 80%;
          height: 80%;
          max-height: 160px;
          max-width: 300px;
          position: relative;

          .title {
            margin: 15px 0 0 15px;
            color: var(--secondary-text-color);
          }

          .value {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: var(--pc-font-large);
          }
        }
      }
    }
  }
}

.advDetailsInfo {
  display: flex;
  font-weight: 400;
  font-size: calc(var(--pc-font-medium) - 2px);
  margin: 5px 0;

  .stt {
    margin-right: 5px;
  }

  .id {
    max-width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }

  .days {
    margin: 0 0 0 10px;
  }
}