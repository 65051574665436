/** @format */

.comCtn {
  .textLabel {
    margin: 12px 0 0 0;
    font-size: var(--pc-font-medium);
  }

  .comEmailInput {
    margin: 5px 0 0 0;
    border: 1px solid var(--tertiary-text-color);
    border-radius: 9px;
    height: 20px !important;
    outline: none;
    background-color: var(--secondary-bg-color);
    width: 90%;
    padding: 7px 12px 7px 12px;
    color: white;
    font-weight: 400;
    font-size: calc(var(--pc-font-small) + 2px);
  }

  .selecting {
    margin: 8px 0 0 0;
    font-size: calc(var(--pc-font-medium) - 1px);
    font-weight: 400;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    b {
        font-weight: 600;
        // color: var(--event-theme-color);
    }
  }

  .suggestionsList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 10px 16px 10px 16px;
      box-sizing: border-box;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.12s linear;

      &.selected {
        
        .name, .email {
          color: var(--tertiary-text-color);
        }
      }

      &:hover {
        background: var(--tertiary-bg-color);
      }

      .name {
        font-size: calc(var(--pc-font-medium) - 1px);

        i {
          font-size: calc(var(--pc-font-small));
          font-weight: 400;
        }
      }

      .email {
        font-size: calc(var(--pc-font-medium) - 2px);
        font-weight: 400;
      }
    }
  }
}
