.myTransactionCtn {
    width: 100%;
    min-height: calc(100vh - 70px);
    padding: 80px 0 0 0;

    .title {
        font-size: var(--pc-font-large);
    }

    .subtitle {
        font-size: var(--pc-font-medium);
        font-weight: 400;
        color: var(--secondary-text-color);
        margin: 0 0 20px 0;
    }
}