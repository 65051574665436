/** @format */

.overview {
  margin: 20px 0 0 0;

  .title {
    font-size: var(--pc-font-medium);
  }

  .subtitle {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  .statisticCtn {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 30px 0;

    .box {
      min-height: 100px;
      width: calc(50% - 10px);
      border-radius: 10px;
      transition: all 0.12s linear;
      box-sizing: border-box;
      padding: 8px 16px 8px 16px;

      .boxTitle {
        display: flex;
        justify-content: space-between;

        label {
          font-size: calc(var(--pc-font-medium));
          color: var(--secondary-text-color);
        }

        .icon {
          font-size: calc(var(--pc-font-small) - 2px);
          color: var(--secondary-text-color);
          transform: translateY(50%);
          cursor: pointer;
        }
      }

      .boxContent {
        text-align: center;
        font-size: var(--pc-font-huge);
        margin: 6px 0 0 0;
      }

      .dataList {
        list-style: none;
        margin: 10px 0 0 0;
        padding: 0;

        li {
          color: var(--primary-text-color);
          font-size: calc(var(--pc-font-medium) - 2px);
          font-weight: 400;
          margin: 0 0 1.5px 0;
          width: 100%;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;

          .icon {
            transform: translateY(2px);
            margin: 0 8px 0 0;
          }
        }
      }

      .FeedbackCtn {
        display: flex;
        align-items: center;
        height: 80%;
        margin: 5px 0 0 0;

        .feedbackOverall {
          text-align: center;
          padding: 0 10px 0 10px;
          box-sizing: border-box;

          .average {
            font-size: calc(var(--pc-font-large));
            color: var(--primary-text-color);
          }

          .unit {
            font-size: calc(var(--pc-font-small));
            text-wrap: nowrap;
            line-height: 1;
          }
        }
      }

      .fbackProgressCtn {
        width: 100%;
        padding: 0 0 0 5px;
        box-sizing: border-box;

        .fbackProgress {
          width: 100%;
          display: flex;
          margin: 0 0 5px 0;

          label {
            font-size: calc(var(--pc-font-medium));
            display: block;
            width: 30px;

            .icon {
              font-size: calc(var(--pc-font-medium) - 3px);
              margin: 0 0 0 3px;
            }
          }
        }
      }
    }
  }

  .participants {
    margin: 25px 0 25px 0;
  }
}

.progressLine {
  margin: 2px 0 0 0;
  height: 20px;
  border-radius: 7px;
  width: 100%;
  overflow: hidden;
}

.progressSub {
  display: flex;

  .sub {
    margin: 8px 0 0 0;
    display: flex;
    align-items: center;

    .color {
      height: 17px;
      width: 17px;
      border-radius: 50%;
      margin: 0 4px 0 0;
    }

    label {
      font-size: calc(var(--pc-font-medium) - 1px);
      margin: 0 20px 0 0;
    }
  }
}

.deleteEventBtn {
  background: red !important;
  color: var(--primary-text-color) !important;
  border: 1px solid red;
  border-radius: 8px !important;
  margin: 20px 0 0 0;
}

.deleteSub {
  color: rgb(255, 173, 173) !important;
  font-weight: 400;
  margin: 2px 0 0 0;
}

.showConfirmDeleteCtn {
  .title {
    font-size: var(--pc-font-medium);
  }

  .subtitle {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  .mainText {
    margin: 15px 0 0 0;
    font-size: calc(var(--pc-font-medium) - 1px);
    font-weight: 400;
  }

  .typeEmailText {
    margin: 18px 0 0 0;
    font-size: calc(var(--pc-font-medium) - 1px);
    font-weight: 400;

    b {
      color: red;
      font-weight: 600;
      user-select: none;
    }
  }

  .confirmInput {
    width: 90%;
    margin: 5px 0 0 0;
    border-radius: 8px;
    padding: 6px 12px 6px 12px;
    outline: none;
    border: none;
    background-color: var(--quaternary-bg-color);
    color: var(--primary-text-color);
  }
}

