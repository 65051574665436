/** @format */

.about {
  padding: 100px 0 0 0;
  min-height: 100vh;
  box-sizing: border-box;

  .group {
    width: 100%;
    display: flex;
    justify-content: start;
    margin: 0 0 50px 0;

    &:nth-child(even) {
      justify-content: end;

      .infoCtn {
        .title,
        .content {
          text-align: right;
        }
      }
    }

    .infoCtn {
      position: relative;
      width: 60%;

      .title {
        font-size: var(--pc-font-huge);
        color: var(--brand-color-light);
        line-height: 1;
        margin: 0 0 10px 0;
      }

      .divider {
        margin: 10px 0;
      }

      .content {
        margin: 0 !important;
        font-size: calc(var(--pc-font-medium) - 1px);
        margin-top: 20px;
        font-weight: 400;
      }
    }
  }

  .itemTitle {
    font-size: var(--pc-font-huge);
    color: var(--brand-color-light);
    text-align: center;
    margin: 80px 0 35px 0;
  }

  .timelineGroup {
    margin: 0 7px;

    .timelineGroup-title {
      font-size: calc(var(--pc-font-large) - 2px);
      color: var(--brand-color-light);
    }
    .timelineGroup-content {
      font-size: calc(var(--pc-font-medium) - 2px);
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    .title, .timelineGroup-title {
      font-size: 18px !important;
    }

    .content, .timelineGroup-content {
      font-size: 11px !important;
    }
  }
}
