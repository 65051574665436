/** @format */

.sponsorReqPreview {
  .title {
    font-size: calc(var(--pc-font-medium) + 2px);
    display: flex;
    align-items: center;

    .tag {
      font-size: var(--pc-font-small);
      margin: 0 0 0 10px;
      padding: 2px 5px 2px 5px;
      border-radius: 3px;

      &.Processing {
        background-color: rgb(147, 128, 1);
      }

      &.Confirmed {
        background-color: green;
      }

      &.Rejected {
        background-color: red;
      }
    }
  }

  .label {
    margin: 10px 0 0 0;
    font-size: calc(var(--pc-font-small) + 2px);
  }

  .content {
    font-size: calc(var(--pc-font-small));
    font-weight: 400;
    color: var(--primary-text-color);
  }

  .link {
    transform: translateY(-20px) !important;

    &:hover {
      color: var(--secondary-text-color);
    }
  }

  a {
    color: var(--primary-text-color);
    margin: 0 0 0 5px;
    font-size: calc(var(--pc-font-small) - 1px) !important;
    transition: all 0.1s linear;

    &:hover {
        opacity: 0.7;
    }
  }

  .btnCtn {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 25px 0 0 0;
    // padding: 0 10% 0 10%;

    .rejectBtn {
      background: transparent;
      color: red;
      border: 1px solid red;
      width: calc(50% - 2px);

      &:hover {
        background-color: red;
        color: var(--primary-text-color);
        border: 1px solid red;
      }
    }

    .acceptBtn {
      background: var(--success-btn-bg-color);
      color: var(--primary-text-color);
      width: calc(50% - 2px);
      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--primary-text-color);
      }
    }
  }

  .caution {
    font-size: calc(var(--pc-font-small) + 1px);
    font-weight: 400;
    text-align: center;
    margin: 20px 0 0 0;
  }
}
