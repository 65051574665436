.progressbar {
    width: 100%;
    border-radius: 15px;
    height: 15px;
    transform: translateY(25%);
    position: relative;
    overflow: hidden;

    .progress {
        height: 15px;
        position: absolute;
        z-index: 999;
        transition: all 0.25s ease-in-out;
    }
}