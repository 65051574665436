/** @format */

$top-space: 60px;

.newEvent {
  width: 100%;
  min-height: calc(100vh - 72px);
  padding: 0 0 5vh 0;

  .eventThemeMask {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: var(--event-theme-color);
    opacity: 0.35;
    transition: 0.12s ease-in-out;
  }

  .interactEle {
    width: 100%;
    background-color: var(--quaternary-opacity-bg-color);
    margin: 15px 0 10px 0;
    padding: 8px 20px 8px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    position: relative;
    box-shadow: 1.5px 1.5px 1px var(--tertiary-bg-color);

    &.multipleInteract {
      padding: 0;
      &:hover {
        background-color: var(--quaternary-opacity-bg-color);
      }

      ul {
        li {
          padding-right: 20px !important;
          padding-left: 15px !important;
          box-sizing: border-box;
          transition: all 0.1s ease-in-out;

          &:hover {
            background-color: var(--quaternary-opacity-bg-hover-color);
          }

          &:nth-child(1) {
            padding-top: 8px !important;
            border-radius: 10px 10px 0 0 !important;
          }

          &:nth-last-child(1) {
            padding-bottom: 8px !important;
            border-radius: 0 0 10px 10px !important;
          }
        }
      }
    }

    &:hover {
      background-color: var(--quaternary-opacity-bg-hover-color);
    }
  }

  .contentCtn {
    margin: $top-space 0 0 0;
    padding: 20px 0 0 0;
    box-sizing: border-box;

    .leftSide {
      .imgWrapper {
        position: relative;
        overflow: hidden;

        &.isLoading {
          animation: imageLoading 2s ease-in-out infinite;
        }

        .eventThumbnail {
          width: 100%;
          border-radius: 20px;
        }

        .addImgBtn {
          position: absolute;
          right: 15px;
          bottom: 15px;

          &.loading {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border-top: 3.5px solid var(--event-theme-color);
            animation: imageLoading 0.8s linear infinite;
          }
        }
      }
      .themeDescrip {
        display: flex;
        justify-content: center;
        align-items: center;

        .themeColor {
          height: 50px;
          width: 60px;
          border-radius: 5px;
          background-color: var(--event-theme-color);
        }

        ul {
          list-style: none;
          margin: 0 0 0 12px;
          padding: 0;

          li {
            &:nth-child(1) {
              font-size: calc(var(--pc-font-medium) - 3px);
              color: var(--secondary-text-color);
            }
            &:nth-child(2) {
              font-size: calc(var(--pc-font-medium) + 2px);
              color: var(--primary-text-color);
            }
          }
        }
      }
    }

    .rightSide {
      padding: 0 40px 0 40px;
      box-sizing: border-box;

      .locationWrapper {
        position: relative;
      }

      .mapCtn {
        width: 100%;
        height: 235px;
        position: relative;
        overflow: hidden;
        border-radius: 15px;
        animation: showup 1.5s ease-in-out;

        .eventMap {
          position: absolute;
          left: -10%;
          top: -32px;
          width: 120%;
          height: 300px;
          border-radius: 15px;
          animation: fadeIn 0.8s ease-in-out;
          scale: 0.8;
        }
      }

      .locationTypingCtn {
        width: calc(100% + 5px);
        // max-height: 400px;
        // overflow-y: scroll;
        background-color: var(--tertiary-bg-color);
        border: 1px solid var(--tertiary-text-color);
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: calc(100% + 10px);
        z-index: 998;
        box-sizing: border-box;
        padding-bottom: 15px;
        animation: onShowCtn 0.2s ease-in-out;

        .loading {
          position: absolute;
          margin: 0;
          padding: 0;
          display: flex;
          right: 20px;
          top: 20px;
          list-style: none;

          li {
            width: 7px;
            height: 7px;
            background-color: var(--primary-text-color);
            margin: 0 3px 0 3px;
            border-radius: 50%;
            animation: jumpAnimation 1s infinite ease-in-out;
          }
        }

        .locationInput {
          background-color: var(--quaternary-text-color);
          font-size: calc(var(--pc-font-medium) - 2px);
          height: calc(var(--pc-font-medium) + 20px) !important;
          padding: 10px 15px 15px 15px;
          height: 45px !important;

          &::placeholder {
            color: var(--tertiary-text-color);
          }
        }

        .suggestion {
          margin: 5px 0 0 16px;
          font-size: var(--pc-font-medium);
          font-weight: 400;
        }

        .noLocation {
          width: 100%;
          text-align: center;
          color: var(--tertiary-text-color);
          font-size: var(--pc-font-medium);
        }

        .address {
          padding: 5px;
          list-style: none;
          margin: 0;
          max-height: 300px;
          overflow-y: scroll;

          li {
            display: flex;
            justify-content: left;
            align-items: center;
            margin: 0 0 0 0;
            padding: 10px 0 10px 16px;
            border-radius: 5px;
            cursor: pointer;
            // box-sizing: border-box;

            .contentCustonLocation {
              font-size: var(--pc-font-medium);
            }

            &:hover {
              background-color: var(--quaternary-bg-color);
            }

            .icon {
              margin: 0 12px 0 0;
            }

            .mainText {
              font-size: var(--pc-font-medium);
            }

            .secondaryText {
              font-size: calc(var(--pc-font-medium) - 4px);
              color: var(--secondary-text-color);
              font-weight: 400;
            }
          }

          .currentSelectedLocation {
            font-size: var(--pc-font-medium);
            margin: 0 0 0 16px;
          }

          .currentLoc {
            margin: 0 0 10px 0;
            font-size: var(--pc-font-medium);
            cursor: default;
            // border-bottom: 1px solid var(--secondary-text-color);

            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      .metaCtn {
        font-size: var(--pc-font-medium);
        padding: 0 0 0 0;
        position: relative;
        width: 100%;

        .timeline {
          width: 1px;
          height: 69%;
          position: absolute;
          top: 45%;
          transform: translateY(-50%);
          border-left: 1px dashed var(--tertiary-text-color);

          &::before,
          &::after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            left: -6px;
            border-radius: 50%;
          }

          &::before {
            top: 0;
            border: 1px solid transparent;
            background-color: var(--tertiary-text-color);
          }

          &::after {
            bottom: -11px;
            border: 1px solid var(--tertiary-text-color);
          }
        }

        .timeStart,
        .timeEnd {
          padding: 0 0 0 20px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          width: 100%;
        }

        .timeStart {
          margin: 5px 0 10px 0;
        }
      }
    }

    .locationInfo {
      display: flex;
      align-items: start;
      width: 100%;

      .icon {
        font-size: calc(var(--pc-font-medium) - 2px);
        margin: 7px 0 0 0;
      }

      ul {
        margin: 0 0 0 8px;
        padding: 0;
        list-style: none;
        width: 90%;

        li {
          &:nth-child(1) {
            font-size: var(--pc-font-medium);
          }

          &:nth-child(2) {
            font-size: calc(var(--pc-font-medium) - 5px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .createEventOptions {
      font-size: var(--pc-font-medium);
      margin: 20px 0 0 0;
    }

    .eventsOptions {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid var(--tertiary-text-color);

        &:nth-child(1) {
          padding: 0 0 10px 0;
        }

        &:nth-child(2) {
          padding: 10px 0 10px 0;
        }

        &:nth-child(3) {
          padding: 10px 0 0 0;
          border-bottom: none;
        }

        label {
          font-size: var(--pc-font-medium);

          .icon {
            font-size: calc(var(--pc-font-medium) - 3px);
            margin: 0 8px 0 0;
            transform: translateY(2px);
          }
        }

        .options {
          color: var(--secondary-text-color);

          label {
            font-size: calc(var(--pc-font-medium) - 2px);
            font-weight: 400;
          }

          .icon {
            margin: 0 2px 0 8px;
            font-size: calc(var(--pc-font-medium) - 7px);
          }
        }
      }
    }

    .newEventBtn {
      width: 100%;
      margin: 25px 0 0 0;

      &:hover {
        background-color: transparent !important;
      }
    }

    .notice {
      font-size: var(--pc-font-small);
      color: var(--secondary-text-color);
      font-weight: 400;
      // text-align: center;
      padding: 2px 0 0 5px;
    }
  }
}

.cropWrapper {
  position: relative;
  height: 350px;

  .reactEasyCrop_Container {
    margin: 30px 0 0 0;
    background: var(--quaternary-bg-color) !important;
    border-radius: 20px;
  }
}

.cropImgBtnCtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 0;
}

.ftagsCtn {
  display: flex;
  flex-wrap: nowrap;

  div {
    margin: 0 8px 0 0;
    max-width: calc((100% / 5) - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.editorModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.428);
  z-index: 998;

  .descriptionEditorCtn {
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-bg-color);
    opacity: 1;
    padding: 2px;
    border-radius: 15px;
    animation: zoomOut 0.2s ease-in-out;

    .eventDescrip {
      font-size: calc(var(--pc-font-large) - 4px);
      padding: 6px 0 15px 12px;
      box-sizing: border-box;
    }

    .ToggleTool {
      border-bottom: 2px solid var(--primary-bg-color);
      background-color: var(--tertiary-bg-color);
      border-radius: 12px 12px 0 0;
      padding: 5px 5px 5px 10px;

      button {
        outline: none;
        border: none;
        padding: 4px 0 2px 4px;
        margin: 0 10px 0 0;
        border-radius: 6px;
        width: 40px;
        height: 40px;
        background: none;
        color: var(--primary-text-color);
        cursor: pointer;
        text-align: center;
        transition: all 0.1s ease-in-out;

        &:hover {
          background: var(--secondary-bg-color);
        }

        &.active {
          background: var(--primary-bg-color);
        }

        font-size: calc(var(--pc-font-medium) - 4px);
      }
    }

    .descriptionEditor {
      // border: 3px solid var(--tertiary-bg-color);
      // border-radius: 10px;
      outline: none;
      padding: 5px 15px 5px 15px;
      box-sizing: border-box;
      min-height: 200px;
      max-height: 400px;
      overflow: scroll;
      width: 600px;
      background: var(--tertiary-bg-color);

      h1 {
        font-weight: 500;
        font-size: var(var(--pc-font-large) + 2px);
        // line-height: calc(var(--pc-font-large) - 10px);
      }

      h2 {
        font-weight: 500;
        font-size: var(var(--pc-font-large) - 1px);
        // line-height: calc(var(--pc-font-large) - 14px);
      }

      h3,
      p {
        font-size: var(--pc-font-medium);
        font-weight: 400;
        line-height: auto !important;
      }

      ul,
      ol {
        font-size: var(--pc-font-medium);
        // line-height: 5px;
        // line-height: 15px;
      }

      &:focus {
        // border: 3px solid var(--quaternary-bg-color);
        outline: none !important;
      }
    }

    .btnCtn {
      display: flex;
      align-items: center;
      justify-content: end;
      margin: 15px 0 0 0;
      padding: 0 15px 10px 0;
      box-sizing: border-box;

      button {
        margin: 0 0 0 15px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 230px;
  }
}

@keyframes onShowCtn {
  0% {
    transform: translateY(-100%);
    scale: 0.2;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    scale: 1;
    opacity: 1;
  }
}

@keyframes jumpAnimation {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes imageLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoomOut {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

@keyframes showup {
  0% {
    height: 0;
  }
  100% {
    height: 235px;
  }
}

@media screen and (max-width: 768px) {
  .leftSide {
    padding: 0 40px 0 40px;
    box-sizing: border-box;
  }
}
