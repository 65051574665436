/** @format */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  overscroll-behavior: none;

  .linearBG {
    // background: rgb(75, 35, 191);
    // background: linear-gradient(
    //   0deg,
    //   rgba(75, 35, 191, 0) 0%,
    //   #8b1f3e3c 60%,
    //   rgba(75, 35, 191, 0.17) 100%
    // );
    background: var(--primary-bg-color);
  }

  .linearBGLighter {
    // background: rgba(89, 141, 252, 0.17);
    // background: linear-gradient(
    //   0deg,
    //   rgba(75, 35, 191, 0) 50%,
    //   #ff80a43c 80%,
    //   rgba(100, 135, 209, 0.17) 100%
    // );
    background: var(--secondary-bg-color);
  }
}

strong {
  font-weight: 700;
}

// Ant design
.ant-btn-primary {
  box-shadow: none !important;
}

.ant-message-notice-content {
  background: var(--secondary-bg-color) !important;
}

.ant-message {
  color: var(--primary-text-color) !important;
  font-size: calc(var(--pc-font-small) + 2px) !important;
}

.ant-message-error > .anticon {
  font-size: calc(var(--pc-font-small) + 3px) !important;
  margin: 0 0 2px 0;
}

.ant-spin {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.ant-dropdown-menu-title-content {
  font-size: var(--pc-font-medium) !important;
}

.divider {
  width: 100%;
  border-bottom: 1px solid var(--tertiary-text-color);
}

.ant-popconfirm-title {
  font-size: calc(var(--pc-font-medium) - 2px) !important;
}

.ant-popconfirm-buttons {
  .ant-btn-sm {
    font-size: calc(var(--pc-font-small)) !important;

    &:hover {
      color: var(--event-theme-color) !important;
      border: 1px solid var(--event-theme-color) !important;
    }
  }

  .ant-btn-primary {
    background: var(--event-theme-color) !important;

    &:hover {
      color: var(--primary-text-color) !important;
      border: 1px solid var(--event-theme-color) !important;
      background: transparent !important;
    }
  }
}

.ant-popover-inner-content {
  font-size: var(--pc-font-small) !important;
}

.ant-timeline-item-tail {
  border-inline-start: 2px solid var(--quaternary-bg-color) !important;
}

.ant-tooltip {
  .ant-tooltip-inner {
    color: var(--primary-text-color) !important;
    background-color: var(--quaternary-bg-color) !important;
  }
  .ant-tooltip-arrow {
    &:before {
      background-color: var(--quaternary-bg-color) !important;
    }
  }
}

svg {
  text {
    font-family: Montserrat !important;
    // font-weight: 600;
  }

  .issuedDate {
    font-weight: 400 !important;
  }
}