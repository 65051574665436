.help {
    min-height: 90vh;
    padding-top: 100px;
    box-sizing: border-box;
    margin: 0 0 30px 0;

    .title {
        font-size: var(--pc-font-huge);
        margin-bottom: 10px;
    }
}