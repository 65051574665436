/** @format */

.tagsModalInput {
  background: var(--quaternary-opacity-bg-color) !important;
  color: white;
  height: auto;
  border-radius: 12px;
  font-size: calc(var(--pc-font-medium));
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
}

.tagsCtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 5px 0 0 0;

    .tagsOption {
        margin: 0 25px 5px 0;
        transition: all 0.1s linear;
        cursor: pointer;
        font-size: var(--pc-font-medium);

        &:hover {
            color: var(--tertiary-text-color);
        }

        &.selected {
            color: var(--brand-color);
        }
    }
}

.tagsTitle {
    font-size: calc(var(--pc-font-medium) + 2px);
    margin: 15px 0 0 0;
}

.addOnTextTag {
    font-size: calc(var(--pc-font-small));
    margin: 0;
    font-weight: 400;
    color: var(--secondary-text-color);
}

.addHashtag {
    margin: 8px 0 0 5px;
    font-size: calc(var(--pc-font-medium));
    color: var(--secondary-text-color);
    transition: all 0.1s linear;
    cursor: pointer;

    &:hover {
        color: var(--primary-text-color);
    }
}

.tagsBtnCtn {
    margin: 30px 0 0 0;

    button {
        width: calc(50% - 10px);
        
        &:nth-child(1) {
            margin: 0 10px 0 0;
        }
    }
}

.tagIcon {
    animation: tagLoading 1.5s linear infinite;
}

@keyframes tagLoading {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}