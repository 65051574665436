/** @format */

.CustomButton {
  box-sizing: border-box;
  border: none;
  outline: none;
  font-weight: 500 !important;
  cursor: pointer;
  transition: 0.1s linear;
  text-decoration: none;

  svg {
    padding: 50% 0 0 0;
    font-size: calc(var(--pc-font-medium) + 2px);
  }

  &.small {
    font-size: calc(var(--pc-font-small) + 2px);
    padding: 7px 15px 7px 15px;
  }

  &.big {
    font-size: calc(var(--pc-font-medium));
    padding: 10px 17px 10px 17px;
    font-weight: 700;
  }

  &.primary {
    background-color: var(--primary-btn-bg-color);
    color: var(--black);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--primary-btn-bg-color);
      background: transparent !important;
      color: var(--primary-text-color);
      backdrop-filter: blur(5px);
    }
  }

  &.secondary {
    background-color: var(--secondary-btn-bg-color);
    color: var(--primary-link-color);

    &:hover {
      background-color: var(--secondary-btn-bg-hover-color);
    }
  }

  &.link {
    padding: 0;
    font-weight: 550;
    background: transparent;
    color: var(--nav-item);

    &:hover {
      color: var(--nav-item-hover);
    }
  }

  &.smooth {
    border-radius: 15px;
  }

  &.sharp {
    border-radius: 8px;
  }

  &.disabled {
    filter: saturate(0.44) brightness(0.7);
    cursor: not-allowed;
  }
}

.btnBadge {
  transform: translate(45%, -165%);
  background: var(--nav-item-selected);
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: var(--pc-font-small);
  border-radius: 50%;
}
