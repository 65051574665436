/** @format */

.Navbar {
  width: 100%;
  max-width: 100vw;
  background: var(--white-opacity-0);
  //   height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 30px 15px 30px;
  box-sizing: border-box;
  transition: all 0.1s linear;
  z-index: 998;

  .logo {
    font-size: calc(var(--pc-font-large));
    font-weight: 600;
    color: var(--primary-color);
    text-decoration: none;
  }

  .navItems,
  .rightNav {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: var(--pc-font-medium);
    list-style: none;

    li {
      color: var(--nav-item);
      margin: 0 12px 0 18px;
      transition: color 0.1s linear;
      cursor: pointer;

      &:nth-child(1) {
        cursor: default;
        &:hover {
          color: var(--nav-item);
        }
      }

      &:hover {
        color: var(--nav-item-hover);
      }

      .userAvt {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }

  .rightNav {
    li:nth-child(1) {
      margin: 0 0 0 0;
    }
  }

  .navItems {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.switchLanguage {
  font-weight: 400;
  color: var(--primary-text-color);
  cursor: pointer;
  transition: all 0.12s linear;
  width: 35px;
  text-align: center;

  &:hover {
    color: var(--brand-color-light);
  }
}

@media screen and (max-width: 768px) {
  .Navbar {
    padding: 5px 10px;

    .logo {
      transform: translateY(5px);
    }

    .navItems,
    .rightNav {
      li {
        margin: 0 6px 0 9px;
        a {
          font-size: 10px !important;
        }

        button {
          font-size: 10px !important;
          padding: 5px 7px;
          border-radius: 10px;
        }

        .switchLanguage {
          font-size: 10px !important;
          padding-top: 3px;
        }
      }
    }
  }
}
