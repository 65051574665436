/** @format */

.participants {
    margin: 20px 0 0 0;

  .title {
    font-size: var(--pc-font-medium);
  }

  .subtitle {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  .headerCtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .addComitteeBtn {
      font-size: var(--pc-font-small);
      font-weight: 400;
      padding: 6px 10px 6px 10px;
      border-radius: 12px;
    }
  }

  .searchCtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 0 0;

    .searchBtn {
      padding: 2px 8px;
      border-radius: 7px;

      &:hover {
        color: var(--event-theme-color);
      }
    }
  }
}
