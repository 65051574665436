/** @format */

.attemptQuizCtn {
  min-height: 100vh;
  margin: 100px 0 0 0;

  .quizName {
    font-size: var(--pc-font-large);
  }

  .quizDescription {
    font-size: calc(var(--pc-font-medium));
    font-weight: 400;
  }

  .infoCtn {
    list-style: none;
    padding: 0;
    margin: 15px 0 0 0;

    li {
      font-weight: 400;
      font-size: calc(var(--pc-font-medium) - 2px);

      .icon {
        font-size: calc(var(--pc-font-medium) -2px);
        transform: translateY(2.2px);
        font-weight: 400;
        margin-right: 2px;
      }
    }
  }

  .startQuizBtn {
    margin: 20px 0 0 0;
    width: 100%;
    border-radius: 8px;
  }

  .viewAllRes {
    font-size: calc(var(--pc-font-medium) - 2px);
    font-weight: 400;
    text-align: center;
    color: var(--secondary-text-color);
    margin: 4px 0 0 0;
    cursor: pointer;
    transition: all 0.1s linear;

    &:hover {
      color: var(--primary-text-color);
    }
  }

  .countDown {
    font-size: var(--pc-font-huge);
    text-align: center;
  }

  .questionAttempt {
    border: 1px solid var(--quaternary-bg-color);
    background: var(--secondary-bg-color);
    border-radius: 10px;
    padding: 10px 18px;
    margin: 0 0 20px 0;
    // height: 130px;
  }
  .questionName {
    font-size: calc(var(--pc-font-medium) + 2px);

    &.notAnswered {
      color: red;
    }
  }

  .answerInput {
    margin: 15px 0 0 0;
    width: 100%;
    border: none;
    outline: none;
    background: var(--quaternary-bg-color);
    border-radius: 5px;
    color: var(--primary-text-color);
    font-size: calc(var(--pc-font-medium) - 1px);
    font-weight: 400;
    padding: 8px 12px;
    box-sizing: border-box;
    resize: none;
  }

  .multileAnswersCtn {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;

    li {
      font-size: calc(var(--pc-font-medium));
      font-weight: 400;
      color: var(--secondary-text-color);
      cursor: pointer;
      transition: all 0.1s linear;
      margin: 0 0 5px 0;

      &:hover {
        color: var(--primary-text-color);
      }

      &.selected {
        color: var(--brand-color-light);
      }

      .icon {
        font-size: calc(var(--pc-font-medium) - 2px);
        transform: translateY(1.5px);
        font-weight: 400;
        margin-right: 6px;
      }
    }
  }
  .countdownClock {
    background: var(--quaternary-bg-color);
    position: fixed;
    top: calc(100px + 120px);
    right: 8%;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &.red {
      color: red;
    }
  }

  .submitBtn {
    position: fixed;
    top: calc(100px + 200px);
    width: 110px;
    right: 6.5%;
    z-index: 999;
  }

  .back {
    margin: 20px 0 0 0;
    font-size: calc(var(--pc-font-medium) - 2px);
    font-weight: 400;
    color: var(--secondary-text-color);
    cursor: pointer;
    transition: all 0.1s linear;

    &:hover {
      color: var(--primary-text-color);
    }

    .icon {
      font-size: 10px;
      transform: translateY(1px);
    }
  }

  .viewAllResponsesCtn {
    margin: 12px 0 0 0;

    .responsesNoCtn {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        background: var(--tertiary-bg-color);
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        transition: all 0.1s linear;
        cursor: pointer;

        &:hover {
          background: var(--quaternary-bg-color);
        }

        &.currView {
          background: var(--brand-color-light);
        }
      }
    }

    .title {
      margin: 12px 0 10px 0;
    }
  }
}
