/** @format */

.addOnOtp {
  margin: 0 0 5px 0;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.otpCtn {
  width: 100%;
  margin: 5px 0 25px 0;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0 0;
    padding: 0;

    li {
      width: calc(100% / 6 - 20px);
      height: calc(var(--pc-font-huge) + 5px);

      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        border-radius: 8px;
        padding: 2px 0 2px 0;
        text-align: center;
        font-size: calc(var(--pc-font-large));
        background: var(--quaternary-bg-color);
        color: var(--primary-text-color);
      }
    }
  }

  .resendOtp {
    margin: 9px 0 0 0;
    color: var(--secondary-text-color);
    font-size: var(--pc-font-small);
    transition: all 0.1s linear;
    cursor: pointer;

    &:hover {
      color: var(--primary-text-color);
    }

    &.notYet {
      color: var(--quaternary-text-color);
      cursor: auto;

      &:hover {
        color: var(--quaternary-text-color);
      }
    }
  }
}
