/** @format */

.EventThemePicker {
  width: 100%;

  .header {
    text-align: center;
    font-size: var(--pc-font-medium);
  }

  .colorPresentation {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 40px 0 0 0;
    padding: 0;
    flex-wrap: wrap;

    li {
      text-align: center;
      position: relative;
      min-width: 80px;
      margin: 0 20px 20px 20px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        opacity: 0.75;
      }

      .preview {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin: 0 0 5px 0;
        border: 3px solid transparent;
      }

      .selected {
        border: 3px solid var(--primary-text-color);
      }

      label {
      }

      .customColor {
        background: rgb(230, 101, 138);
        background: radial-gradient(
          circle,
          rgba(230, 101, 138, 1) 0%,
          rgba(204, 98, 213, 1) 19%,
          rgba(123, 73, 255, 1) 31%,
          rgba(55, 135, 255, 1) 40%,
          rgba(71, 201, 126, 1) 55%,
          rgba(251, 216, 91, 1) 67%,
          rgba(255, 150, 65, 1) 77%,
          rgba(255, 89, 101, 1) 85%,
          rgba(216, 97, 86, 1) 90%,
          rgba(210, 212, 215, 1) 97%
        );
      }

      .customColorCode {
        font-size: var(--pc-font-small);
      }
    }
  }
}

// .colorPickerEvent {
//     opacity: 0 !important;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%
// }
