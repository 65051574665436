/** @format */

.Footer {
  width: 100vw;
  max-width: 100vw;
  background: var(--primary-bg-color);

  .content {
    border-top: 1px solid var(--quaternary-text-color);
    padding: 0 15px 0 15px;
    display: flex;
    justify-content: space-between;

    .btnItems {
      display: flex;
      list-style: none;
      align-items: center;
      padding: 0;

      &:nth-child(1) {
        li {
          margin: 0 30px 0 0;

          &:nth-child(1) {
            font-size: calc(var(--pc-font-medium) + 2px);
            font-weight: 600;
          }
        }
      }

      &:nth-child(2) {
        li {
          margin: 0 0 0 30px;
          scale: 1.2;
        }
      }
    }
  }

  .copyright {
    font-size: var(--pc-font-small);
    font-weight: 400;
    color: var(--tertiary-text-color);
    width: 100%;
    text-align: center;
    padding: 0 0 5px 0;
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    .content {
      flex-wrap: wrap;
      justify-content: start;

      .btnItems {
        li {
          margin: 0 22px 0 0 !important;
          a {
            font-size: 11px !important;
          }
        }

        &:nth-child(2) {
            width: 100% !important;
            display: flex !important;
            justify-content: end !important;
        }
      }
    }
  }
}
