.AdvertiseCtn {
    margin: 20px 0 30px 0;

    .title {
      font-size: var(--pc-font-medium);
    }

    .subtitle {
      font-size: var(--pc-font-small);
      color: var(--secondary-text-color);
      font-weight: 400;
      margin: 0 0 7px 0;
    }

    .money {
        margin: 10px 0 0 0;
        font-size: var(--pc-font-medium);
        color: var(--primary-text-color);
        font-weight: 400;
    }

    .paymentBtn {
        margin: 10px 0 0 0;
        width: 100%;
        border-radius: 8px;
    }
}