/** @format */

.fbackCtn {
  margin: 20px 0 0 0;

  .title {
    font-size: var(--pc-font-medium);
  }

  .subtitle {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  .fbacksList {
    margin: 25px 0 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .Fback {
      width: calc(50% - 15px);
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      min-height: 120px;
      margin: 0 0 15px 0;

      .fbackUser {
        display: flex;
        align-items: center;

        img {
          height: 25px;
          width: 25px;
          border-radius: 50%;
        }

        .name {
          font-size: calc(var(--pc-font-medium) - 1px);
          margin: 0 0 0 8px;
          width: calc(60% - 25px - 8px);
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: none;
        }
      }

      .fbackStarsEmCtn {
        display: flex;
        margin: 0;
        justify-content: end;
        width: 40%;

        .star {
          font-size: calc(var(--pc-font-medium));
          margin: 0 3px 0 0;
          color: var(--event-theme-color);
          transition: all 0.12s ease-out;
        }
      }

      .fbackContent {
        max-width: 100%;
        font-size: calc(var(--pc-font-medium) - 2px);
        font-weight: 400;
        margin: 8px 0 0 0;
        text-wrap: wrap;
      }
    }
  }
}

.fbackprogressbarCtn {
  width: 100%;
  margin: 15px 0 0 0;

  .progressFullbar {
    height: 18px;
    width: 100%;
    background-color: rgb(55, 55, 55);
    border-radius: 7px;
    overflow: hidden;
    display: flex;

    .progress {
      height: 100%;
      width: 0;
      position: relative;
        
      .subContent {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: var(--pc-font-small);
      }
    }
  }

  .labelCtn {
    display: flex;
    margin: 10px 0 0 0;

    .label {
      margin: 0 25px 0 0;
      display: flex;
      align-items: center;

      .color {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin: 0 6px 0 0;
      }

      .text {
        display: flex;
        align-items: center;
        font-size: var(--pc-font-medium);

        .icon {
          font-size: calc(var(--pc-font-medium) - 2px);
          margin-left: 2px;
        }
      }
    }
  }
}
