/** @format */

.IntroductionSection {
  margin: 120px 0 100px 0;
  padding: 0 15%;

  .headline {
    font-size: calc(var(--pc-font-huge) - 1px);
  }

  .content {
    margin: 18px 0 0 0;
    font-size: calc(var(--pc-font-medium) - 1px);
    font-weight: 400;
    color: var(--secondary-text-color);
  }

  .starIcon {
    position: absolute;
    opacity: 0;
    animation: starLight 1.7s linear infinite;
  }

  .cyrstalCtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;

    .Crystal {
      animation: crystalAnimation 6s linear infinite;
    }
  }

  .exploreBtn,
  .createEventBtn {
    margin-top: 20px;
    width: calc(50% - 10px);
    border-radius: 8px;
  }

  .exploreBtn {
    margin-right: 10px;
  }
}

.HomePage {
  width: 100%;
  height: auto;

  .homeSection {
    margin-bottom: 60px;
    min-height: 180px;
    box-sizing: border-box;

    .empty {
      text-align: center;
      color: var(--tertiary-text-color);
      font-size: var(--pc-font-medium);
      font-weight: 400;
    }

    .viewmore {
      color: var(--primary-text-color);
      font-size: calc(var(--pc-font-small) + 3px);
      font-weight: 400;
      text-align: end;
      padding: 0 0 0 20px;
      cursor: pointer;
      transition: all 0.12s linear;

      &:hover {
        color: var(--brand-color-light);
      }

      .icon {
        font-size: calc(var(--pc-font-small) - 2px);
        margin: 0 0 0 3px;
      }
    }

    .popularOrganizersCtn {
      display: flex;
      flex-wrap: wrap;

      .orgCard {
        width: calc(50% - 20px);
        margin-bottom: 20px;
        background-color: var(--quaternary-bg-color);
        border-radius: 12px;
        box-sizing: border-box;
        padding: 15px;
        transition: all 0.1s linear;
        cursor: pointer;

        &:nth-child(odd) {
          margin-right: 20px;
        }

        &:hover {
          background-color: var(--tertiary-bg-color);
        }

        img {
          border-radius: 50%;
          height: 55px;
          width: 55px;
        }

        .orgName {
        }

        .totalEvents {
          font-size: calc(var(--pc-font-medium) - 1px);
          font-weight: 400;
          color: var(--secondary-text-color);
        }
      }
    }
  }
}

.homeTitle {
  text-align: center;
  font-size: calc(var(--pc-font-large) - 3px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 25px 0;

  label {
    margin: 0 20px;
    position: relative;

    .icon {
      position: absolute;
      padding: 0 0 0 2px;
      top: 50%;
      transform: translateY(-50%);
      font-size: calc(var(--pc-font-medium));
      margin: 0 0 0 5px;
      color: var(--brand-color-light);
      transition: all 0.12s linear;

      &:hover {
        color: var(--brand-color-hover);
        cursor: pointer;
      }
    }
  }
}

@keyframes starLight {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}

@keyframes crystalAnimation {
  0% {
    scale: 1.5;
    transform: translateY(0);
    filter: drop-shadow(3px 3px 10px var(--brand-color-light));
  }
  30% {
    scale: 1.7;
    transform: translateY(-30px);
    filter: drop-shadow(3px 3px 40px white);
  }
  100% {
    scale: 1.5;
    transform: translateY(0);
    filter: drop-shadow(3px 3px 10px var(--brand-color-light));
  }
}

@media screen and (max-width: 768px) {
  .HomePage {
    .viewmore {
      font-size: 10px !important;
    }

    .icon {
      font-size: 8px !important;
    }
  }

  .homeTitle {
    font-size: 14px;
  }

  .IntroductionSection {
    .headline,
    .content {
      text-align: center;
    }

    .headline {
      font-size: 14px;
    }

    .content {
      font-size: 10px;
    }

    .exploreBtn,
    .createEventBtn {
      font-size: 10px;
      padding: 5px 9px;
    }

    .Crystal {
      margin: 30px 0 0 0;
    }

    margin: 100px 0 20px 0;
  }

  .popularOrganizersCtn {
    .orgCard {

      .heartSizeCtl {
        transform: translate(10px, -10px);
        font-size: 12px !important;
      }
      img {
        height: 30px !important;
        width: 30px !important;
      }
      .orgName {
        font-size: 11px !important;
      }

      .totalEvents {
        font-size: 9px !important;
      }
    }
  }
}
