/** @format */

.eventManage {
  width: 100%;
  min-height: 100vh;
  padding: 130px 0 0 0;

  .eventThemeMask {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: var(--event-theme-color);
    opacity: 0.35;
    transition: all 0.12s ease-in-out;
  }

  .infoCtn {
    display: flex;
    justify-content: space-between;

    .info {
      .host {
        font-size: calc(var(--pc-font-medium) - 1px);
        color: var(--secondary-text-color);
        cursor: pointer;

        &:hover {
          color: var(--primary-text-color);
        }

        .icon {
          transform: translateY(1.5px);
        }
      }

      .eventName {
        font-size: calc(var(--pc-font-large));
        margin: 3px 0 0 0;
        min-width: 200px;
        max-width: 50vw;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .settingNav {
    display: flex;
    list-style: none;
    font-size: calc(var(--pc-font-medium) - 2px);
    color: var(--tertiary-text-color);
    margin: 0;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding: 15px 0 0 0;

    li {
      font-weight: 400;
      margin: 0 12px 0 0;
      padding: 0 5px 5px 5px;
      cursor: pointer;
      transition: all 0.12s ease-in-out;
      position: relative;

      &.active {
        border-bottom: 1px solid var(--primary-text-color);
        color: var(--primary-text-color);
      }

      &:hover {
        color: var(--secondary-text-color);
      }

      .lock {
        color: red;
        margin-left: 2px;
        transform: translateY(1px);
        font-size: calc(var(--pc-font-small) - 1px);
      }
    }
  }
}
