/** @format */

.policy {
  padding-top: 100px;
  min-height: 100vh;
  box-sizing: border-box;

  .mainTitle {
    font-size: var(--pc-font-huge);
    color: var(--brand-color-light);
  }

  .group {
    margin: 10px 0 0 0;
    .title {
      font-size: var(--pc-font-large);
    }
    .description {
      font-size: var(--pc-font-medium);
      font-weight: 400;
      margin: 0 0 30px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .policy {
    .mainTitle {
      font-size: 18px !important;
    }
    .title {
      font-size: 15px !important;
    }

    .description {
      font-size: 11px !important;
    }
  }
}
