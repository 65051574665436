.certificatePageCtn {
    margin: 20px 0 30px 0;

    .title {
      font-size: var(--pc-font-medium);
    }

    .subtitle {
      font-size: var(--pc-font-small);
      color: var(--secondary-text-color);
      font-weight: 400;
      margin: 0 0 7px 0;
    }

    .svgCertiCtn {
        margin: 15px 0 0 0;
        display: flex;
        justify-content: center;
    }

    .selectBox {
        cursor: pointer;

        &.selected {
            color: lime;
        }
    }

    .certifiedBtn {
        width: 100%;
        margin: 15px 0;
        border-radius: 8px;
        background: var(--event-theme-color);
    }

    .viewBtn {
        cursor: pointer;
        font-weight: 400;

        &:hover {
            opacity: 0.8;
        }

        .icon {
            font-size: 12px;
            transform: translate(-2.5px, 2.5px);
        }
    }
  }