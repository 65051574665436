/** @format */

.ViewEvent {
  min-height: 100vh;
  padding: 130px 0 0 0;
  box-sizing: border-box;

  b,
  a {
    color: var(--event-theme-color) !important;
  }

  .eventThemeMask {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: var(--event-theme-color);
    opacity: 0.35;
    transition: all 0.12s ease-in-out;
  }

  .leftSide {
    .imgWrapper {
      height: auto;
      background-color: red;
      background-color: rgba(69, 69, 69, 0.386);
      border-radius: 30px;

      .previewImg {
        width: 100%;
        border-radius: 30px;
      }
    }

    .manageSection {
      width: 100%;
      height: 55px;
      background-color: var(--quaternary-bg-color);
      border-radius: 15px;
      margin: 15px 0 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px 0 25px;
      box-sizing: border-box;

      div {
        font-size: calc(var(--pc-font-medium) - 3px);
      }

      .btn {
        svg {
          padding: 0;
          font-size: var(--pc-font-small);
          margin: 0 0 0 5px;
          transform: translateY(1px);
        }

        &:hover {
          filter: brightness(1.2);
          font-weight: 400;
        }
      }
    }

    .hostSection {
      margin: 20px 0 0 0;

      .hostTitle {
        font-weight: 600;
        font-size: var(--pc-font-medium);
        color: var(--primary-text-color);
        margin: 0 0 3px 0;
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0 0;

        .personalData {
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 8px 0 0;
          }

          .personalCtn {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }

            .hostName {
              font-size: var(--pc-font-medium);
            }
          }

          .addition {
            display: block;
            margin: 0 0 0 40px;
            padding: 0;
            font-size: var(--pc-font-small);
            color: var(--secondary-text-color) !important;
            font-weight: 400;
            transform: translateY(-5px);

            &:hover {
              color: var(--primary-text-color) !important;
            }
          }
        }
      }
    }

    .locationSection {
      font-weight: 600;
      font-size: var(--pc-font-medium);
      color: var(--primary-text-color);
      margin: 30px 0 0 0;

      .locationTitle {
        margin: 0 0 3px 0;
      }

      .locationName {
        font-size: var(--pc-font-medium);
        color: var(--primary-text-color);
        font-weight: 600;
        margin: 5px 0 0 0;
      }

      .locationAddress {
        font-size: var(--pc-font-small);
        color: var(--secondary-text-color);
        font-weight: 400;
        margin: 0;
      }

      .mapWrapper {
        position: relative;
        height: 300px;

        .eventMap {
          position: absolute;
          top: -26%;
          left: -33%;
          width: 163%;
          margin: 12px 0 20px 0;
          outline: none;
          border-radius: 16px;
          height: 370px;
          overflow: scroll;
          scale: 0.6;
          // border: none;
        }
      }
    }
  }

  .rightSide {
    box-sizing: border-box;
    padding: 0 0 0 60px;

    .featured {
      font-weight: 600;
      font-size: var(--pc-font-medium);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      b {
        font-weight: 600;
        font-size: var(--pc-font-medium);
        color: var(--brand-color);
      }

      .icon {
        transform: translateY(3px);
      }
    }

    .eventName {
      margin: 20px 0 0 0;
      font-weight: 600;
      font-size: var(--pc-font-large);
    }

    .eventTagsCtn {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;

      .tag {
        font-size: var(--pc-font-small);
        color: var(--secondary-text-color);
        margin: 0 10px 0 0;
        cursor: pointer;
        transition: all 0.1s linear;

        &:hover {
          color: var(--event-theme-color);
        }
      }
    }

    .eventInfo {
      font-size: calc(var(--pc-font-medium) - 2px);
      list-style: none;
      margin: 30px 0 0 0;
      padding: 0;
      color: var(--secondary-text-color);
      font-weight: 500;

      li {
        margin: 0 0 2px 0;

        .icon {
          transform: translateY(3px);
          margin: 0 6px 0 0;
        }
      }
    }

    .register {
      margin: 35px 0 0 0;

      .registerTitle {
        font-size: var(--pc-font-medium);
        color: var(--primary-text-color);
      }

      .registerSub {
        font-size: var(--pc-font-small);
        color: var(--secondary-text-color);
        font-weight: 400;
      }

      .regBtn {
        margin: 12px 0 0 0;
        width: 100%;
        border-radius: 12px;
        background-color: var(--event-theme-color);
        color: white;
        // text-shadow: 0.5px 0.5px 0.5px rgb(100, 100, 100);
        font-size: calc(var(--pc-font-medium) + 2px);

        &.pending {
          display: flex;
          justify-content: center;
          height: 45px;
        }
      }

      .currUser {
        margin: 2px 0 0 0;
        font-size: var(--pc-font-small);
        color: var(--secondary-text-color);
        font-weight: 400;
      }
    }

    .divider {
      width: 100%;
      height: 45px;
      border-bottom: 1px solid var(--secondary-text-color);
    }

    
  }
  .description {
    margin: 15px 0 25px 0;
    font-size: var(--pc-font-medium);
    color: var(--primary-text-color);
    font-weight: 600;

    .descriptionTitle {
      margin: 0 0 15px 0;
    }

    .descriptionContent {
      h1 {
        font-weight: 500;
        font-size: var(var(--pc-font-large) + 2px);
      }

      h2 {
        font-weight: 500;
        font-size: var(var(--pc-font-large) - 1px);
      }

      h3,
      p {
        font-size: var(--pc-font-small);
        font-weight: 400;
        margin: 0;
      }

      ul,
      ol {
        font-size: var(--pc-font-small);
        line-height: 5px;
      }

      &:focus {
        // border: 3px solid var(--quaternary-bg-color);
        outline: none !important;
      }
    }
  }
}
.eventMap {
  width: 100%;
  height: 500px;
}

.checkinModal {
  text-align: center;

  .title {
    font-size: var(--pc-font-large);
    color: var(--primary-text-color);
  }

  .desc {
    font-size: calc(var(--pc-font-medium) - 2px);
    color: var(--secondary-text-color);
  }

  .qrCtn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 24px 0 20px 0;

    .qr {
      scale: 1.1;
    }
  }

  .username {
    margin: 5px 0 0 0;
    font-size: var(--pc-font-medium);
  }

  .registeredTime {
    font-size: var(--pc-font-small);
  }
}

.fbackCtn {
  width: 100%;
  margin: 12px 0 0 0;

  .fbackStarsCtn {
    display: flex;
    margin: 0 0 10px 0;

    .star {
      font-size: calc(var(--pc-font-huge) - 4px);
      cursor: pointer;
      margin: 0 10px 0 0;
      color: var(--event-theme-color);
      transition: all 0.12s ease-out;

      &:hover {
        filter: brightness(1.3);
      }
    }
  }

  textarea {
    width: 99%;
    border-radius: 12px;
    outline: none;
    resize: none;
    border: 1px solid var(--event-theme-color);
    color: var(--primary-text-color);
    font-size: calc(var(--pc-font-medium));
    overflow-y: scroll;
    padding: 5px 8px 5px 8px;
    box-sizing: border-box;

    &::placeholder {
      color: var(--secondary-text-color);
      font-weight: 400;
    }
  }

  .feedbackContent {
    font-weight: 400;
    font-size: var(--pc-font-medium);
  }
}

.sponsorRequestCtn {
  .title {
    font-size: calc(var(--pc-font-medium) + 2px);
    display: flex;
    align-items: center;

    .tag {
      font-size: var(--pc-font-small);
      margin: 0 0 0 10px;
      padding: 2px 5px 2px 5px;
      border-radius: 3px;

      &.Processing {
        background-color: rgb(147, 128, 1);
      }

      &.Confirmed {
        background-color: green;
      }

      &.Rejected {
        background-color: red;
      }
    }
  }

  .contactHost {
    font-size: calc(var(--pc-font-small) + 1px);
    cursor: pointer;
    color: var(--event-theme-color);
    transition: all 0.12s linear;

    &:hover {
      opacity: 0.8;
    }
  }

  .label {
    font-size: var(--pc-font-medium);
    margin: 18px 0 2px 0;

    label {
      color: red;
    }
  }

  .inputSponsor {
    margin: 5px 0 0 0;
    border: 1px solid var(--tertiary-text-color);
    border-radius: 9px;
    height: 20px !important;
    outline: none;
    background-color: var(--secondary-bg-color);
    width: 94%;
    padding: 7px 12px 7px 12px;
    color: white;
    font-weight: 400;
    font-size: calc(var(--pc-font-small) + 2px);
  }

  .createSponsorReqBtn {
    width: 100%;
    margin: 30px 0 0 0;
    color: var(--primary-text-color);
    background: var(--event-theme-color);

    &.deleteBtn {
      background: red;
    }
  }
}

.sponsorText {
  font-size: var(--pc-font-small);
  color: var(--primary-text-color);
  cursor: pointer;
  transition: all 0.1s linear;
  margin: 0 0 15px 0;
  font-weight: 400;

  &:hover {
    color: var(--event-theme-color);
  }
}

.warning {
  font-size: var(--pc-font-small);
  color: var(--secondary-text-color);
  font-weight: 400;
  margin: 2px 0 0 0;
  text-align: center;
}
.sponsorContent {
  font-size: calc(var(--pc-font-medium) - 2px);
  color: var(--secondary-text-color);
  // margin-left: 12px;
}

.sponsorAddCtn {
  .title {
    font-size: var(--pc-font-medium);
  }

  .subtitle {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  .notCompleteOrder {
    margin: 10px 0 0 0;
    font-size: var(--pc-font-small);
    font-weight: 400;
  }

  .descript {
    font-size: calc(var(--pc-font-small) - 1px);
    font-weight: 400;
    margin: 6px 0 0 0;
    color: var(--secondary-text-color);
    text-align: right;
    cursor: pointer;
    transition: all 0.1s linear;

    &.back {
      text-align: left;
    }

    .icon {
      transform: translateY(2px);
    }

    &:hover {
      color: var(--brand-color-light);
    }
  }

  .OrderHistory {
    max-height: 210px;
    overflow-y: scroll;
    font-size: calc(var(--pc-font-small) + 2px);
    color: var(--secondary-text-color);
    font-weight: 400;
  }

  input {
    width: 90%;
    background-color: var(--tertiary-bg-color);
    font-size: var(--pc-font-medium);
    padding: 6px 12px 6px 12px;
    border-radius: 6px;
    border: none;
    outline: none;
    color: var(--primary-text-color);
    margin: 15px 0 0 0;
  }

  .deposit {
    margin: 25px 0 0 0;
    width: 95%;
    background: var(--event-theme-color);
    border-radius: 8px;
    color: var(--primary-text-color);
  }
}

#reader {
  margin: 20px 0 0 0;
  height: auto;
  border-radius: 30px !important;
  overflow: hidden;
  border: none !important;

  video,
  #qr-shaded-region {
    border-radius: 30px !important;
  }
  img {
    display: none !important;
  }

  button {
    padding: 8px 15px 8px 15px !important;
    border-radius: 12px !important;
    background: var(--event-theme-color) !important;
    color: var(--primary-text-color) !important;
    border: none;
    outline: none;
  }

  #html5-qrcode-anchor-scan-type-change {
    display: none !important;
  }
}

.invalidData {
  text-align: center;
  color: #f5574c;
}

.checkInTitle {
  border: none !important;
  font-size: var(--pc-font-medium);
  color: var(--primary-text-color);
}

.informationConfirm {
  .title {
  }

  .info {
    font-weight: 400;
    font-size: calc(var(--pc-font-medium) - 2px);

    b {
      color: var(--event-theme-color);
      font-weight: 600;
    }
  }

  .cancelCheckin,
  .checkin {
    margin: 15px 0 0 0;
    width: calc(50% - 7px);
    border-radius: 8px;
  }

  .checkin {
    margin: 0 0 0 7px;
    background-color: var(--event-theme-color);
    color: var(--primary-text-color);
  }
}

#reader__dashboard_section_csr {
  span {
    display: block !important;
    font-size: var(--pc-font-medium) !important;
    margin: 10px 0 10px 0 !important;

    #html5-qrcode-select-camera {
      margin: 0 0 0 10px !important;
      padding: 3px 7px 3px 7px !important;
      border-radius: 4px !important;
    }
  }
}

.closeCheckinIcon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  &:hover {
    color: var(--secondary-text-color);
  }
}

.quizCtn {
  margin: 10px 0 0 0;
  display: flex;
  flex-wrap: wrap;

  .quiz {
    padding: 7px;
    box-sizing: border-box;
    width: calc(50% - 15px);
    margin: 0 5px 0 5px;
    border-radius: 8px;

    .name {
      font-size: calc(var(--pc-font-medium) - 1px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      font-size: calc(var(--pc-font-small));
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      margin: 0;
    }

    .infoCtn {
      list-style: none;
      padding: 0;
      margin: 5px 0 0 0;

      li {
        font-weight: 400;
        font-size: calc(var(--pc-font-small) - 2px);

        .icon {
          font-size: calc(var(--pc-font-small) - 2px);
          transform: translateY(2.2px);
          font-weight: 400;
          margin-right: 2px;
        }
      }

    }
    .attemptBtn {
      margin: 8px 0 0 0;
      border-radius: 5px;
      padding: 2px 5px;
      width: 100%;
      font-size: calc(var(--pc-font-small));
    }
  }
}

@media screen and (max-width: 768px) {
  .displayRow {
    display: flex;
    flex-direction: column-reverse;
  }
  .rightSide {
    padding: 0 0 0 11vw !important;
    margin: 0 !important;
  }

  .description {
    .descriptionTitle {
      margin-bottom: 0 !important;
    }
  }
}