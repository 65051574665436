/** @format */

.emptyCalendar {
  margin: 40px 0 0 0;
  svg {
    font-size: 180px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.5;
    color: var(--secondary-text-color);
    filter: drop-shadow(0 0 60px var(--tertiary-text-color));
  }

  .emptyCalendar_text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: calc(var(--pc-font-large) - 4px);
    margin: 10px 0 0 0;
    color: var(--secondary-text-color);
    text-shadow: 0 0 60px var(--tertiary-text-color);
  }
}
