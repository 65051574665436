/** @format */
$gridWidth: 80px;

.gridSwitch {
  position: relative;
  .gridOptsCtn {
    list-style: none;
    display: flex;
    background-color: var(--secondary-btn-bg-color);
    padding: 4px;
    border-radius: 11px;

    li {
      font-size: calc(var(--pc-font-medium) - 2px);
      width: $gridWidth;
      padding: 4px 1.5px 4px 1.5px;
      text-align: center;
      color: var(--tertiary-text-color);
      cursor: pointer;

      &:hover:not(.selected) {
        color: var(--secondary-text-color);
        transition: all 0.12s linear;
      }

      &.selected {
        color: var(--primary-text-color);
        // background-color: var(--secondary-btn-bg-hover-color);
        border-radius: 10px;
      }
    }
  }

  .selectedBg {
    width: calc($gridWidth + 4px);
    height: calc(var(--pc-font-medium) + 8px);
    padding: 4px 0 4px 0;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--secondary-btn-bg-hover-color);
    transition: all 0.1s ease-out;
  }
}
