/** @format */

.transactionsCtn {
  .title {
    margin: 20px 0 0 0;
    font-size: var(--pc-font-medium);
  }

  .subtitle {
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    font-weight: 400;
    margin: 0 0 7px 0;
  }
}

.customRefundCtn {
  display: flex;

  .inputCnt {
    margin: 5px 12px 0 0;

    .label {
      font-size: calc(var(--pc-font-medium) - 2px);
    }

    input {
      width: 220px;
      margin: 3px 0 0 0;
      padding: 5px 12px 5px 12px;
      border-radius: 6px;
      border: none;
      outline: none;
      color: var(--primary-text-color);
      font-size: calc(var(--pc-font-small));
      // background: var(--quaternary-text-color);

      &::placeholder {
        color: var(--tertiary-text-color);
        font-weight: 400;
      }
    }
  }
}
.refundButton {
  width: 500px;
  margin: 15px 0 0 0;
  border-radius: 8px !important;
  background: red !important;
  color: var(--primary-text-color) !important;
}

.caution {
  margin: 5px 0 20px 0;
  color: var(--primary-text-color);
  font-weight: 400;
  font-size: calc(var(--pc-font-small) - 1px);
  width: 500px;
  text-align: center;
}

.searchCtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 0;

  .searchBtn {
    padding: 2px 8px;
    border-radius: 7px;

    &:hover {
      color: var(--event-theme-color);
    }
  }
}
