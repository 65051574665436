/** @format */

.profileCtn {
  width: 100%;
  min-height: 92.5vh;
  padding: 80px 0 25px 0;
  box-sizing: border-box;

  .userAvt {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 4px solid var(--primary-bg-color);
    z-index: 3;
  }

  .changeAvatarIcon {
    position: absolute;
    z-index: 4;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--pc-font-small);
    color: var(--secondary-text-color);
    cursor: pointer;
  }

  .infoCtn {
    width: 100%;
    margin: 40px 0 0 0;
    padding: 50px 0 15px 0;
    text-align: center;
    border-radius: 15px;
    position: relative;

    .name {
      margin: 4px 0 0 0;
      font-size: var(--pc-font-large);
    }

    .email {
      margin: 3px 0 0 0;
      font-size: var(--pc-font-medium);
      font-weight: 400;
    }

    .editIcon {
      position: absolute;
      right: 18px;
      top: 12px;
      cursor: pointer;
      font-size: var(--pc-font-small);
      color: var(--tertiary-text-color);
    }

    .edit {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        display: block;
        width: 95%;
        max-width: 500px;
        text-align: center;
        margin: 7px 0 0 0;
        // border: 1px solid var(--tertiary-bg-color);
        border: none;
        outline: none;
        color: white;
        background-color: rgba(255, 255, 255, 0.05);
        padding: 5px 10px 5px 10px;
        font-size: var(--pc-font-medium);
        border-radius: 10px;

        &::placeholder {
          font-weight: 400;
          color: var(--tertiary-text-color);
          font-style: italic;
        }
      }

      .btnCtn {
        margin: 25px 0 0 0;
        width: 100%;

        button {
          border-radius: 10px;
          width: 20%;
          min-width: 100px;
          max-width: 200px;
        }

        .cancel {
        }

        .save {
          margin: 0 0 0 10px;
          background-color: var(--brand-color);
          color: var(--primary-text-color);
        }
      }
    }
  }

  .title {
    margin: 20px 0 0 0;
    font-size: var(--pc-font-medium);
  }
  .subtitle {
    margin: 0 0 20px 0;
    font-size: var(--pc-font-small);
    font-weight: 400;
    color: var(--secondary-text-color);
  }

  .userNotFound {
    text-align: center;
    font-size: var(--pc-font-huge);
  }
}

@media screen and (max-width: 768px) {
  .subtitle {
    font-size: 11px !important;
  }
}