/** @format */

$spacing: 0.5rem;
$paddingContainer: 2rem;

.authCover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.479);
  z-index: 998;
}

.authenPage {
  //   padding: 15vh 0 8vh;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -55%);
  background-color: var(--secondary-bg-color);
  border-radius: 21px;
  box-shadow: 1px 1px 15px var(--tertiary-text-color);
  z-index: 998;
  animation: zoomOut 0.3s ease-in-out;

  .authen-content {
    min-width: 22rem;
    max-width: 25rem;
    width: 30vw;
    background-color: transparent;
    border-radius: 22px;
    padding: $paddingContainer;
    border: 1px solid var(--secondary-btn-bg-hover-color);

    .form-container {
      display: flex;
      flex-direction: column;
      // gap: 1rem;
      height: 100%;

      .backBtn {
        display: flex;
        align-items: center;
        text-align: left;
        width: 40px;
        margin: 0 0 10px 0;
        transform: translateX(-10px);

        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      .inputLabel {
        font-size: var(--pc-font-medium);
        margin: 0 0 8px 0;
      }

      .emailInput {
        border: 2px solid var(--quaternary-bg-color);
        height: 45px !important;
        background: var(--quaternary-bg-color);
        font-size: calc(var(--pc-font-medium) - 3px);
        border-radius: 10px;
        margin: 0;
        box-sizing: border-box;
        padding: 0 12px 0 12px;
      }

      .register {
        color: var(--tertiary-text-color);
        text-align: right;
        cursor: pointer;
        font-size: var(--pc-font-small);
        margin: 5px 0 20px 0;

        &:nth-child(2) {
          // text-decoration: underline;
        }

        &:hover {
          color: var(--secondary-text-color);
        }
      }
    }

    .mainIcon {
      font-size: var(--pc-font-huge);
    }

    .title {
      text-align: left;

      h2 {
        margin: 8px 0 0 0;
        text-align: left;
      }

      p {
        margin: 2px 0 20px 0;
        color: var(--secondary-text-color);
        font-size: calc(var(--pc-font-medium) - 1px);
      }
    }
  }
}

.divide {
  position: relative;
  width: 100%;
  margin: 15px 0 15px 0;

  .divider {
    position: absolute;
    bottom: 10px;
    left: -2rem;
    border-bottom: 1px solid var(--secondary-btn-bg-color);
    width: calc(100% + 4rem);
    top: 50%;
    transform: translateY(-50%);
  }
}

.button-login {
  width: 100%;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing;
  font-weight: 400 !important;
  margin: 0;
}

.icon-button {
  padding: 0 !important;
  font-size: 1rem !important;
}

.active-form {
  display: flex !important;
}

.register-form {
  input {
    border: 1px solid var(--quaternary-bg-color) !important;
  }

  .support-box {
    margin: 2px 0 15px 0;
    text-align: right;
  }
}

@keyframes zoomOut {
  0% {
    scale: 0.2;
  }
  100% {
    scale: 1;
  }
}

@media screen and (max-width: 768px) {
  .authenPage {
    top: calc(50% + 20px);
    width: 90vw;
    box-sizing: border-box;

    .authen-content {
      max-width: 100% !important;
      padding: 30px 20px !important;
      box-sizing: border-box;

      .mainIcon {
        font-size: 20px !important;
      }

      .title {
        h2 {
          font-size: 17px !important;
        }
        p {
          font-size: 11px !important;
        }
      }

      .container {
        max-width: 100% !important;
      }

      .form-container {
        .inputLabel {
          font-size: 12px !important;
        }
      }
    }
  }
}
