.bottomModal {
    width: 100vw;
    position: fixed;
    min-height: 280px;
    bottom: 0;
    backdrop-filter: blur(10px);
    z-index: 998;
    border-top: 2px solid var(--event-theme-color);
    border-radius: 40px 40px 0 0;
    padding: 40px;
    box-sizing: border-box;
    transition: all 0.12s ease-in-out;
    animation: showModal 0.2s ease-in-out;
}

.closeAnimate {
    transform: translateY(100%);
}

@keyframes showModal {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
    
}