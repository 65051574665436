/** @format */

.explore {
  width: 100vw;
  min-height: 100vh;
  padding: 80px 0 0 0;
  box-sizing: border-box;

  .title {
    font-size: var(--pc-font-huge);
    font-weight: 600;
  }

  .popularGroup {
    margin: 45px 0 0 0;

    .groupTitle {
      font-size: var(--pc-font-large);
      margin: 0 0 20px 0;

      .icon {
        font-size: var(--pc-font-medium);
        margin: 0 0 0 8px;
        cursor: pointer;

        &:hover {
          color: var(--secondary-text-color);
        }
      }

      button {
        margin: 0 0 0 8px;
        
        .icon {
          font-size: var(--pc-font-small);
        }
      }
    }
  }
}

.locationsCtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  .location {
    display: flex;
    width: calc((100% / 3) - 20px);
    min-width: 200px;
    margin: 0 20px 20px 0;
    align-items: center;
    animation: showUp 0.35s ease-out;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .infoCtn {
      padding: 0 0 0 10px;
      width: 80%;

      .name {
        font-size: var(--pc-font-medium);
        font-weight: 600;
        margin: 0 0 2px 0;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 1;
      }

      .amount {
        font-size: var(--pc-font-small);
        color: var(--secondary-text-color);
        font-weight: 400;
        margin: 0 0 0 0;
      }
    }
  }
}

@keyframes showUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .infoCtn {
    .name {
      font-size: 11px !important;
    }
    .amount {
      font-size: 9px !important;
    }
  }
}