.Ticket {

    .description {
        margin: 0;
        padding: 7px 0 0 15px;

        li {
            margin: 0 0 6px 0;
            font-size: calc(var(--pc-font-small) + 2px);
        }
    }

    .limitTitle {
        margin: 20px 0 3px 0;
        font-size: var(--pc-font-medium);
        color: var(--primary-text-color);
    }

    .eventSizeBtnCtn {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 0 0;

        button {
            width: 49%;
        }
    }
}