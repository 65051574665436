/** @format */

$top: 12vh;

.EventsPage {
  // min-height: calc(100vh + $top + 65px);
  min-height: calc(100vh - 68px);
  width: 100%;

  .eventsContainer {
    // position: absolute;
    // top: 50%;
    width: 100%;
    height: 100%;

    .EventsCtn_Header {
      margin-top: $top;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: var(--pc-font-huge);
      }
    }

    .EventsCtn_Content {
      // height: calc(100vh - $top - 65px - 70px - 10px);
      width: 100%;
      min-height: 400px;
      padding: 20px 0 20px 0;
      box-sizing: border-box;
      overflow-y: scroll;
    }
  }
}
